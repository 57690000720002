<template>
  <div class="w-full flex flex-col">
    <!-- <bread-crumbs class="flex justify-end text-xs" :items="routes" /> -->
    <h3 class="text-start mt-2 text-2xl mb-2">Manifiestos</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">
      Mostrando Manifiesto:
      {{this.$route.params.number != null ? this.$route.params.number : 'N/A'}}</h4>

    <table-packages class="mt-4 "/>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapGetters } from 'vuex';
// import BreadCrumbs from '@/components/BreadCrumbs.vue';
import TablePackages from '@/components/TablePackages.vue';
// import request from '@/api/request-services';
import UserMixin from '@/mixins/UserMixin';
// import Icon from '@/components/Icon.vue';

export default {
  mixins: [UserMixin],
  components: {
    // BreadCrumbs,
    TablePackages,
    // Icon,
  },
  data() {
    return {
      routes: [],
    };
  },
  methods: {
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
    // async loadManifestInfo() {
    //   const response = await request.requestGET(`awbs/${this.$route.params.id}`, this.getToken);
    //   if (response.status === 200) {
    //     console.log('response...', response.data);
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  mounted() {
    // this.loadRoutesBreadCrumbs(this.$router);
    // this.loadManifestInfo();
  },
};
</script>
