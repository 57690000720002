<template>
  <div class="w-full bg-white p-2 overflow-auto" >
    <div v-if="items.length>0">
       <div  v-for="item in items" :key="item.id">
      <div class="w-full flex justify-between mb-4">
        <label class="self-center my-2">{{item.number != null ? item.number : 'N/A'}}</label>
      </div>
    <b-table
      class="text-sm text-center"
      :fields="fields"
      :items="item.packages"
      responsive="sm"
    >
     <template #cell(id)="data">
         <!-- <b class="text-information cursor-pointer">
          <router-link
            class="no-underline"
            :to="{ name: 'AWBs Detail', params: { id: data.item.id, number: data.value   } }">
            {{data.value}}
          </router-link>
        </b> -->
        GVT-{{data.value}}
      </template>
      <template #cell(consignee)>
        test consignee
      </template>
      <template #cell(identification)>
        10000000
      </template>
      <template #cell(packing)>
        BAG
      </template>
      <template #cell(carrier)>
        USPS
      </template>

      <template #cell(enabled)="data">
        <div class="flex justify-center">
            <div
            class="px-2 py-2 text-white rounded"
            :class="data.value ? 'bg-green-500' : 'bg-red-500'">
            <span> {{data.value ? 'Activo' : 'Inactivo'}}</span>
          </div>
        </div>
      </template>

      <template #cell(warehouse_id)="data">
         <div class="flex justify-center">
              <div
            class="px-2 py-2 text-white self-center rounded "
            :class="data.item.enabled ? 'bg-blue-400' : 'bg-gray-300'">
            <icon class="text-center m-auto" name="ListIcon" />
          </div>
        </div>
      </template>

      <template #cell(delete)>
         <div class="flex justify-center">
               <div
            class="px-2 py-2 text-white self-center rounded text-xs bg-red-500" >
            <icon class="text-center m-auto" name="TrashIcon" />
          </div>
        </div>
      </template>

      <template #cell(print)>
        <div class="flex justify-center">
          <div
            class="px-2 py-2 mr-4 text-white self-center rounded text-xs bg-gray-500" >
            <icon class="text-center m-auto" name="PrinterIcon" />
          </div>
          <div
            class="px-2 py-2 text-white self-center rounded text-xs bg-gray-500" >
            <icon class="text-center m-auto" name="DocumentTextIcon" />
          </div>
        </div>
      </template>

      <template #cell(download)>
        <div class="flex justify-center">
          <div
            class="px-2 py-2 mr-4 text-white self-center rounded  bg-green-700" >
            .pdf
          </div>
          <div
            class="px-2 py-2 text-white self-center rounded  bg-green-700" >
            .xlsx
          </div>
        </div>
      </template>

      <template #cell(closed)="data">
         <div class="flex justify-center">
              <div
            class="px-2 py-2 text-white self-center rounded "
            :class="data.value ? 'bg-red-500' : 'bg-gray-400'">
            <icon v-if="data.value" class="text-center m-auto" name="LockOpenIcon" />
            <icon v-else class="text-center m-auto" name="LockClosedIcon" />
          </div>
        </div>
      </template>
      <template v-slot:custom-foot>
      <!-- You can customize this however you want, this is just as an example -->
        <tr>
          <th v-for="field in fields" :key="field.key">
            {{ field.label }}
          </th>
        </tr>
        <tr>
          <td :colspan="1">
          </td>
          <td :colspan="1">
          </td>
          <td :colspan="1">
          </td>
          <td  :colspan="1">
          </td>
           <td  :colspan="1">
              total: 1
          </td>
           <td  :colspan="1">
          </td>
           <td  :colspan="1">
          </td>
           <td  :colspan="1">
             1
          </td>
           <td  :colspan="1">
             1
          </td>
             <td  :colspan="1">
               1
          </td>
             <td  :colspan="1">
               1
          </td>
             <td  :colspan="1">
               1
          </td>
             <td  :colspan="1">
          </td>
             <td  :colspan="1">
          </td>
          <td  :colspan="1">
          </td>
        </tr>
      </template>
    </b-table>
    </div>
    </div>
    <div class="px-4 pt-4 flex justify-center" v-else>
      <p>No hay datos disponibles</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon.vue';
import request from '@/api/request-services';
import TimezoneMixin from '@/mixins/TimezoneMixin';

export default {
  name: 'DashboardTable',
  mixins: [TimezoneMixin],
  components: { Icon },
  computed: {
    rows() {
      return this.items.length;
    },
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    handleCreateAdmin() {
      const role = 5;
      this.$router.push({ name: 'Crear Usuario', params: { role } });
    },
    async handleData() {
      const response = await request.requestGET(`awbs/${this.$route.params.id}`, this.getToken);

      if (response.status === 200) {
        this.items = response.data.children;
      }
    },
  },
  mounted() {
    this.handleData();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      items: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'shipper', label: 'Shipper' },
        { key: 'consignee', label: 'Consignee' },
        { key: 'identification', label: 'Identification' },
        { key: 'pieces', label: 'Pieces' },
        { key: 'packing', label: 'Packing' },
        { key: 'description', label: 'Description' },
        { key: 'length', label: 'L.' },
        { key: 'width', label: 'W.' },
        { key: 'height', label: 'H.' },
        { key: 'weight', label: 'Weight' },
        { key: 'volumetric_weight', label: 'Vol.' },
        { key: 'carrier', label: 'Carrier' },
        { key: 'tracking', label: 'Tracking No.' },
        { key: 'attachments', label: 'Attachments' },
      ],
    };
  },
};
</script>

<style scoped>
  .text-information {
    color: var(--primary-blue) !important;
  }

  ol, ul {
      padding-left: 2rem !important;
  }
</style>
